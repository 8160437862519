import React from 'react';
import parse from 'html-react-parser';


export const InitialText = ({ text }) => {
    return <b><span style={{ color: '#221b17' }}>sankalp@Hackstrix:~$ {text} </span></b>
  };

export const OutputText = ({ text }) => {
    return <span style={{ color: '#221b17' }}>> {parse(text)} </span>
  };

