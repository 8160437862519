import React from 'react';
import './App.css';
// import useTypewriter from './useTypewriter'; // Adjust the path as necessary
import { useState, useRef, useEffect } from 'react';
// import TypewriterText from './TypewriterText';
import {InitialText, OutputText} from './InitialText';
import logo from './img/strix_logo.png'




const backgroundStyle = {
  width: '100vw',
  height: '100vh',
  backgroundColor:'#f1ece6',
  color: '#221b17',
  marginTop: 0,
  paddingTop: '20px',
}

const navBarStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 50px',
  margin: '0px 100px',
}

const headingStyle = {
  fontFamily: 'Monoton',
  // maxWidth: '1500px',
  fontSize: '2vw',  
  textAlign: 'center',
  paddingTop: '20px',
  color: '#221b17',
  textShadow: '2px 2px #f1ece6',
  margin: '0px 5%',
}

const headingDashStyle = {
  margin: '0 0px',
  padding: '0 0px',
}

const subHeadingStyle = {
  fontFamily: 'Georgia',
  fontSize: '1vw',
  textAlign: 'left',
  color: '#221b17',
  textShadow: '2px 2px #f1ece6',
  paddingTop: '0px',
  cursor: 'pointer',
  
}

const subHeadingParaStyle = {
  fontFamily: 'Georgia',
  fontSize: '0.8em',
  textAlign: 'left',
  wordWrap: 'break-word',
  maxWidth: '160px',
}


const consoleStyle = {
  marginTop: '200px',
  backgroundColor: '#f1ece6',
  color: '#b9b4af',
  fontFamily: 'Courier New, Courier, monospace',
  padding: '20px',
  margin: '20px auto',
  width: '45%',
  height: '60%',
  overflowY: 'auto',
  borderRadius: '5px',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
  textAlign: 'left',
  // marginTop: '100px',
};

const inputStyle = {
  backgroundColor: '#f1ece6',
  color: '#221b17',
  fontFamily: 'Courier New, Courier, monospace',
  border: 'none',
  outline: 'none',
  width: '100%',
};


const NeofetchDiv = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 0px',
  margin: '0px 10px',
}

function App() {
  const [input, setInput] = useState('');
  const [consoleOutput, setConsoleOutput] = useState([]);
  // const [typewriterFinished, _] = useState(true);
  // const consoleText = useTypewriter('Weelcome to my personal website console!\n You can use the following commands to interact with the console\n> help \n> about \n> contact\n', 40, () => setTypewriterFinished(true));
  // const consoleText = 'Weelcome to my personal website console!\n You can use the following commands to interact with the console\n> help \n> about \n> contact\n'
  const consoleRef = useRef(null);
  const inputRef = useRef(null);



  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleInputSubmit = (e) => {
    if (e.key === 'Enter') {
      processInput(input);
      setInput('');
    }
  };

  const processInput = (input) => {
    // Process the input and generate output
    setConsoleOutput((prevOutput) => [...prevOutput, { text: `${input}`, isCommand: true }]);
    let output;
    switch (input.toLowerCase()) {
      case 'clear':
        setConsoleOutput([]);
        return;
      case 'help':
        output = 'Available commands: <ul><li>help</li> <li>about</li> <li>contact</li> <li>projects</li> <li> exeperience<ul>\n';
        break;
      case 'projects':
        output = '<h3> Projects </h3> <table class=project-table> <tr> <th>Name</th> <th>Description</th> <th>Start Date</th> <th>End Date</th> </tr> <tr> <td>Project 1</td> <td>Description 1</td> <td>2022-01-01</td> <td>2022-02-01</td> </tr> <tr> <td>Project 2</td> <td>Description 2</td> <td>2022-03-01</td> <td>2022-04-01</td> </tr> </table>';
        break;
      case 'experience':
        output = '<h3> Experience </h3> <p> I have worked at Company A as a software developer for 2 years. I have experience in web development, mobile app development, and machine learning. </p>';
        break;
      case 'about':
        output = '<h3> About me </h3> <p> I am a software developer with a passion for building innovative solutions. I have experience in web development, mobile app development, and machine learning. I am always eager to learn new technologies and work on challenging projects. </p>';
        break;
      case 'contact':
        output = 'You can contact me at <a href="mailto:s24narul@uwaterloo.ca">s24narul@uwaterloo.ca</a>';
        break;
      default:
        output = `Command not found: ${input}`;
    }
    setConsoleOutput((prevOutput) => [...prevOutput, { text: output, isCommand: false }]);
  };

  useEffect(() => {
    if (consoleRef.current) {
      consoleRef.current.scrollTop = consoleRef.current.scrollHeight;
    }
  }, [consoleOutput]);



  return (
    <div className="App" style={backgroundStyle}>
      <div style={navBarStyle}>
        <div onClick={()=>{processInput('projects')}} className='subheading-button'>
          <h3 className="subheading-animation" style={subHeadingStyle}>Workshop</h3>
          <p className="subheading-animation" style={subHeadingParaStyle}>Hands-on sessions and practical projects, made with passion  .</p>
        </div>
        <div onClick={()=>{processInput('about')}} className='subheading-button'>
          <h3 className="subheading-animation" style={subHeadingStyle}>Specialities</h3>
          <p className="subheading-animation" style={subHeadingParaStyle}>Areas of expertise and unique skills.</p>
        </div>
        <h1 className="heading-animation" style={headingStyle}>
          <span style={headingDashStyle}>--</span> Sankalp Narula <span style={headingDashStyle}>--</span>
        </h1>
        <div onClick={()=>{processInput('contact')}} className='subheading-button'>
          <h3 className="subheading-animation" style={subHeadingStyle}>Origins</h3>
          <p className="subheading-animation" style={subHeadingParaStyle}>My background, journey, and inspirations.</p>
        </div>
        <div onClick={()=>{processInput('contact')}} className='subheading-button'>
          <h3 className="subheading-animation" style={subHeadingStyle}>Contact</h3>
          <p className="subheading-animation" style={subHeadingParaStyle}>Get in touch for collaborations and inquiries.</p>
        </div>
      </div>
      <div className="subheading-animation" style={consoleStyle}  ref={consoleRef} onClick={()=> {inputRef.current.focus()}}>
        <span style={{ color: '#221b17' }}><b>sankalp@Hackstrix:~$</b> neofetch</span> 
        <p>Strix Linux 2.1.3-arch1-1 (tty1)</p>
        <div style={NeofetchDiv}><img src={logo} alt="Strix Logo"></img>
        <div>
          <b>Hackstrix@Strix</b><br />
          ----------------<br />
          <b>OS:</b> Strix Linux 2.1.3-arch1-1<br />
          <b>Host:</b> HP Pavilion<br />
          <b>Kernel:</b> 5.14.1-arch1-1<br />
          <b>Uptime:</b> 2 hours, 30 minutes<br />
          <b>Shell:</b> bash 5.1.8<br />
          <b>Terminal:</b> xterm<br />
          <b>CPU:</b> Intel i5-1035G1 (8) @ 3.6GHz<br />
          <b>GPU:</b> Intel Iris Plus Graphics G1<br />
          <b>Memory:</b> 6108MiB / 15874MiB<br />
          ----------------<br />
        </div>
        </div>
        {/* <p style={{whiteSpace: "pre-line"}}>{consoleText}</p> */}
        {(
          <>
            {consoleOutput.map((line, index) => (
              <p key={index}>
                {line.isCommand ? <InitialText text={line.text} /> : <OutputText text={line.text} />} 
              </p>
            ))}
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <b><span style={{ color: '#221b17' }}>sankalp@Hackstrix:~$</span> </b>{/* Replace #yourColorCode with the desired color */}
              <input
                type="text"
                value={input}
                onChange={handleInputChange}
                onKeyDown={handleInputSubmit}
                style={inputStyle}
                ref={inputRef}
                autoFocus
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default App;